import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

/** COMPONENTS */
import { BaseTableComponent } from './table/base-table.component';
import { DialogComponent } from './dialog/dialog.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
@NgModule({
  imports: [
    CommonModule,

    MatTableModule,
    MatSortModule,
    MatPaginatorModule,

    MatDialogModule,
    MatIconModule,
  ],
  declarations: [BaseTableComponent, DialogComponent, FileUploadComponent],
  exports: [BaseTableComponent, DialogComponent, FileUploadComponent],
})
export class LibModule {}
