import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/** COMPONENTS */
import { SidebarComponent } from '../../modules/core/sidebar/components/sidebar.component';
import { AuthenticationService } from '../../modules/core/authentication/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { error } from 'console';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent {
  @ViewChild(SidebarComponent, { static: true }) sidebar: SidebarComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private authService: MsalService,
    private httpClient: HttpClient) {}

  logout() {
    this.authenticationService.logout();
  }

  getUsername () {
    return this.authService.instance.getAllAccounts()[0].username;
  }

  async getAvatar () {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: this.authService.instance.getAllAccounts()[0],
    };

    this.authService.acquireTokenSilent(accessTokenRequest).subscribe(
      {
        next: (result) => {
          this.httpClient.get(`https://graph.microsoft.com/v1.0/me/photos/48x48/$value`, {
            headers: {
              "Authorization": result.accessToken,
              "Accept": "image/jpeg",
            },
            responseType: "blob"
          }).subscribe( data => {
            const url = window.URL || window.webkitURL;
            let x = url.createObjectURL(data);
            document.getElementById("avatar-image").setAttribute("src", x);
            return x;
          },
          error => {
            console.log(error);
            return;
          }
          )

        },
        error: (error) => {
          console.log(error);
          return;
        }
      }
    )
  }

  ngAfterViewInit() {
    this.getAvatar();
  }
}
