import { Component, ViewChild } from '@angular/core';
import { SidebarComponent } from '../../sidebar/components/sidebar.component';
/** SERVICES */
import { AuthenticationService } from './../../authentication/authentication.service';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {
  constructor(private authenticationService: AuthenticationService) {}
  @ViewChild(SidebarComponent, { static: true }) sidebar: SidebarComponent;

  logout() {
    this.authenticationService.logout();
  }
}
