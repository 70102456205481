import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/** DOMAIN */
import { UserLogin } from './components/login/models/login-user.model';
import { User } from '../../administration/components/user/user.model';
import { ResponseState } from '../../../shared/entities/enums/response-state.enum';

/** SERVICES */
import { UserService } from '../../administration/components/user/user.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private userService: UserService, 
    private authService: MsalService,
    private router: Router) {}

  login(): Promise<ResponseState> {
    return new Promise((resolve) => {
      this.authService.loginPopup()
      .subscribe({
        next: (result) => {
          resolve(ResponseState.SUCCESS);
        },
        error: (error) => {
          console.log(error);
          resolve(ResponseState.FAILURE);
        }
      });
    });
  }

  // login(userLogin: UserLogin): Promise<ResponseState> {
  //   return new Promise((resolve) => {
  //     localStorage.removeItem('token');
  //     this.userService
  //       .getUserByLocalCredentials(userLogin)
  //       .subscribe((user: User) => {
  //         if (user) {
  //           localStorage.setItem('token', JSON.stringify(user));
  //           resolve(ResponseState.SUCCESS);
  //         }
  //         if (!localStorage.getItem('token')) {
  //           resolve(ResponseState.FAILURE);
  //         }
  //       });
  //   });
  // }

  logout() {
    localStorage.removeItem('token');
    this.authService.logoutPopup({
      mainWindowRedirectUri: "/"
    });
    this.router.navigate(['/authentication/login']);
  }

  isLoggedIn(){
    return localStorage.getItem('token');
  }
}
