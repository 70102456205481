import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

/** DOMAIN */
import { User } from './user.model';
import { UserLogin } from '../../../core/authentication/components/login/models/login-user.model';
import { PagedListDto } from '../../../../shared/entities/models/paged-list-dto';
import { TableMetadata } from '../../../../shared/entities/models/table-metadata';

@Injectable({ providedIn: 'root' })
export class UserService {
  private url: string = `${environment.baseUrl}/user`;
  constructor(private httpClient: HttpClient) {}

  get(id: number): Observable<User> {
    return this.httpClient.get<User>(`${this.url}/${id}`);
  }

  getAll(tableMetadata?: TableMetadata): Observable<PagedListDto<User>> {
    return this.httpClient.post<PagedListDto<User>>(
      `${this.url}s`,
      tableMetadata
    );
  }

  getUserByLocalCredentials(entity: UserLogin): Observable<User> {
    return this.httpClient.post<User>(`${this.url}/user-login`, entity);
  }

  add(entity: User) {
    return this.httpClient.post(this.url, entity);
  }

  update(entity: User) {
    return this.httpClient.patch(this.url, entity);
  }

  delete(id: number) {
    return this.httpClient.delete(`${this.url}/${id}`);
  }
}
