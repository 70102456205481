import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from "@azure/msal-browser";
import { AuthenticationLayoutComponent } from './layouts/authentication/authentication-layout.component';

/** COMPONENTS */
import { MainLayoutComponent } from './layouts/main/main-layout.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';

const AppRoutes: Routes = [
	{ path: '', redirectTo: 'accommodationunit/home', pathMatch: 'full' },
	{
		path: '',
		component: AuthenticationLayoutComponent,
		children: [{
			path: 'authentication',
			loadChildren: () => import('./modules/core/authentication/authentication.module').then((module) => module.AuthenticationModule)
		}]
	},
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [AuthenticationGuard],
		children: [
			{
				path: 'accommodationunit',
				loadChildren: () => import('./modules/accommodation-unit/accommodation-unit.module').then((module) => module.AccommodationUnitModule),
			},
			{
				path: 'administration',
				loadChildren: () => import('./modules/administration/administration.module').then((module) => module.AdministrationModule),
			}
		],
	},
	{ path: '**', redirectTo: 'accommodationunit/home', pathMatch: 'full' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
	imports: [
		RouterModule.forRoot(AppRoutes, {
			// Don't perform initial navigation in iframes or popups
			initialNavigation:
				!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
					? "enabledNonBlocking"
					: "disabled", // Set to enabledBlocking to use Angular Universal
			useHash: true
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
