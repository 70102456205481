<h6 mat-dialog-title style="text-align: center; font-size: 24px">
  {{ data.header }}
</h6>
<mat-dialog-content style="text-align: center; font-size: 20px">
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end">
  <button
    type="button"
    class="btn btn-outline-success ml-1 mr-1 mb-2"
    icon="pi pi-check"
    mat-button
    [mat-dialog-close]="true"
  >
    Yes, delete
  </button>
  <button
    type="button"
    class="btn btn-outline-danger ml-1 mr-1 mb-2"
    icon="pi pi-times"
    mat-button
    mat-dialog-close
  >
    Cancel
  </button>
</mat-dialog-actions>
