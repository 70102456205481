<!-- SIDEBAR -->
<mat-drawer-container class="main-sidebar" [class.sidebar-opened]="sidebar.opened" autosize>
  <mat-drawer #sidebar class="main-sidebar" mode="side" opened="true">
    <app-sidebar></app-sidebar>
  </mat-drawer>

  <!-- NAVBAR -->
  <div class="main-navbar" [class.main-navbar-expand]="!sidebar.opened">
    <div class="navbar-wrapper">
      <div class="navbar-content"></div>
      <div class="navbar-profile">
        <button class="hamburger-btn" title="show/hide menu"  mat-button (click)="sidebar.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <button class="logout-btn" mat-button [matMenuTriggerFor]="menu">
          <span>{{getUsername()}}</span>
          <img id = "avatar-image" class="ml-3 mb-2 rounded-circle" style="width: 35px;" />
          <!-- <mat-icon class="ml-3 mb-2">account_circle</mat-icon> -->
        </button>

        <mat-menu #menu="matMenu">
          <button class="logout-btn" mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <!-- MAIN PANEL -->
  <div class="main-panel" [class.main-panel-expand]="!sidebar.opened">
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-drawer-container>

<!-- <app-spinner></app-spinner> -->
