<h1>File Upload</h1>
<h6 mat-dialog-title>{{ data.header }}</h6>
<mat-dialog-content>
  <p style="font-size: 16px">{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn-primary mr-1 mb-2" mat-button mat-dialog-close>
    Cancel
  </button>
  <button class="btn btn-warning ml-1 mb-2" mat-button [mat-dialog-close]="true">
    Delete
  </button>
</mat-dialog-actions>