import { FilterMetadata } from './../../shared/entities/models/filter-metadata';
import {
  Output,
  EventEmitter,
  Component,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { getListProperties } from '../decorators/list.decorator';
import { Filter, getFilterProperties } from '../decorators/filter.decorator';

/** DOMAIN */
import { TableMetadata } from './../../shared/entities/models/table-metadata';
import { SortMetadata } from './../../shared/entities/models/sort-metadata';
import { PagingMetadata } from '../../shared/entities/models/paging-metadata';

/** SERVICES */
import { BaseTableService } from './base-table.service';
import { TableRowDeleteResult } from '../../shared/entities/models/table-row-delete-result';
@Component({
  selector: 'app-base-table',
  templateUrl: './base-table.component.html',
})
export class BaseTableComponent implements OnChanges, OnInit {
  @Input() pagedListDto: any;
  @Input() tableMetadata: TableMetadata;
  @Input() tableHeader;
  @Input() type: any;
  @Input() showAddBtn: boolean = false;
  @Input() showDeleteBtn: boolean = false;

  @Output() onLoad = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  @Output() onShowDetails = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onFilter = new EventEmitter();
  @Output() onSort = new EventEmitter();
  @Output() onPaginate = new EventEmitter();

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;

  columns: string[];
  columnsWithFilter: string[];
  showFormDetails: boolean = false;

  isAdmin: boolean = true;
  isModerator: boolean = true;
  isGuest: boolean = true;
  constructor(private baseTableService: BaseTableService, private cd: ChangeDetectorRef) {
    this.baseTableService.showFormDetails$.subscribe((result) => {
      this.showFormDetails = result;
    });
  }

  ngOnInit() {
    this.tableMetadata = {
      filterMetadata: [],
      sortMetadata: {
        orderBy: 'Id',
        orderDirection: 'ASC',
      },
      pagingMetadata: {
        offset: 0,
        pageCount: 1,
        pageIndex: 0,
        pageSize: 0,
        totalCount: 0,
      },
    };

    this.onLoad.emit(this.tableMetadata);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'pagedListDto': {
            if (this.pagedListDto) {
              this.setDataSource();
              this.setDataSourceAttributes();
            }
          }
        }
      }
    }
  }

  setDataSource() {
    this.columns = this.getColumns();
    this.columnsWithFilter = this.getColumnsWithFilter();
    this.columns.push('actions');

    this.dataSource = new MatTableDataSource(this.pagedListDto.pagedData);
    this.tableMetadata.pagingMetadata = this.pagedListDto.pagingMetadata;
  }

  setDataSourceAttributes() {
    this.dataSource.sort = this.matSort;
  }

  getColumns(): string[] {
    const listProperties = getListProperties(this.type);
    return listProperties ? Object.getOwnPropertyNames(listProperties) : null;
  }

  getColumnsWithFilter(): string[] {
    const filterProperties = getFilterProperties(this.type);
    return filterProperties
      ? Object.getOwnPropertyNames(filterProperties)
      : null;
  }

  add() {
    this.showFormDetails = true;
    this.onAdd.emit();
  }

  showDetails(entity: any) {
    this.showFormDetails = true;
    this.onShowDetails.emit(entity);
  }

  delete(entity: any) {
    this.onDelete.emit(<TableRowDeleteResult>{
      entity: entity,
      tableMetadata: this.tableMetadata,
    });
  }

  filter(filterValue: string, columnName: string) {
    if (filterValue.length >= 3) {
      const columnNormalized = columnName.replace('filter_', '');
      const metadata: FilterMetadata = {
        filterValue: filterValue,
        filterColumn:
          columnNormalized.charAt(0).toUpperCase() + columnNormalized.slice(1),
      };
      let filter = this.tableMetadata.filterMetadata.find(
        (filter) => filter.filterColumn === metadata.filterColumn
      );
      if (filter) {
        filter = metadata;
      } else {
        this.tableMetadata.filterMetadata.push(metadata);
      }
      this.onFilter.emit(this.tableMetadata);
    } else if(filterValue.length === 0){
      this.tableMetadata.filterMetadata = [];
      this.onFilter.emit(this.tableMetadata);
    }
  }

  sort(sort: Sort) {
    this.tableMetadata.sortMetadata = <SortMetadata>{
      orderBy: sort.active,
      orderDirection: sort.direction,
    };
    this.onSort.emit(this.tableMetadata);
  }

  paginate(pagingMetadata: any) {
    this.tableMetadata.pagingMetadata.pageIndex = pagingMetadata.pageIndex;
    this.tableMetadata.pagingMetadata.pageSize = pagingMetadata.pageSize;
    this.onPaginate.emit(this.tableMetadata);
  }
}
