import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addOcpApimSubscriptionKey(request));
  }

  addOcpApimSubscriptionKey(request: HttpRequest<any>) {
    return request.clone({
        setHeaders: {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }
    })
  }
}