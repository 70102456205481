

<div class="sidebar">
  <div class="sidebar-wrapper">
    <div class="wrapper">
      <div class="section">
      </div>
      <div class="sidebar">
        <div class="profile">
          <img src="../../../../assets/images/shared/logo.png" alt="Valamar" />
          <h3>Valamar</h3>
          <p>Budgeting and pricing</p>
        </div>
        <ul class="nav" style="height: 100%; overflow-y: auto; overflow-x: hidden">
          <app-sidebar-items></app-sidebar-items>
        </ul>
      </div>
    </div>

  </div>
</div>
