import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
	private authService: MsalService,
	private router: Router) {}

  canActivate(
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
  ): boolean {
	if (this.authService.instance.getAllAccounts().length > 0) {
		let allowedRoles = route.data.roles as Array<string>;
		let userRoles = this.authService.instance.getAllAccounts()[0].idTokenClaims.roles;

		for (var role of userRoles) {
			if (allowedRoles.includes(role)) {
				return true;
			}
		}

		return false;
	}
	else {
		this.router.navigate(['authentication/login']);
		return false;
	}
  }
}

